import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createPopper } from '@popperjs/core'
import { addPackageDrivingRange, getDetailPackageDrivingRange2, updatePackageDrivingRange } from '@/api/package-driving-range'
import Cleave from 'vue-cleave-component'

export default {
  name: 'MasterPackageDrivingRange',
  components: {
    Cleave,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    listCurrency: {
      type: Array,
      required: true,
    },
    listGolfClass: {
      type: Array,
      required: true,
    },
    listCompany: {
      type: Array,
      required: true,
    },
    listTransaction: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      addNewRateEffectiveTime: 1,
      detailMasterPackage: {
        DistributionMethod: 'ByTurn',
        OpenDateSell: this.getUTC('start', 'day'),
        CloseDateSell: this.getUTC('start', 'day'),
        StartDate: this.getUTC('start', 'day'),
        ExpirationDate: this.getUTC('start', 'day'),
      },
      packageId: null,
      inputMask: this.inputMaskByCurrency(),
      isLoading: false,
      length: 0,
    }
  },
  computed: {
    addNewRateEffectiveTimeOptions() {
      return [
        {
          title: this.$t('golf_common_date_range'),
          value: 1,
        },
        {
          title: this.$t('golf_common_optional'),
          value: 0,
        },
      ]
    },
    listWeeklyCode() {
      return [
        {
          text: this.$t('golf_common_monday'),
          value: 'MON',
        },
        {
          text: this.$t('golf_common_tuesday'),
          value: 'TUE',
        },
        {
          text: this.$t('golf_common_wednesday'),
          value: 'WED',
        },
        {
          text: this.$t('golf_common_thursday'),
          value: 'THU',
        },
        {
          text: this.$t('golf_common_friday'),
          value: 'FRI',
        },
        {
          text: this.$t('golf_common_saturday'),
          value: 'SAT',
        },
        {
          text: this.$t('golf_common_sunday'),
          value: 'SUN',
        },
      ]
    },
  },
  watch: {
    detailMasterPackage: {
      handler(value) {
        this.inputMask = this.inputMaskByCurrency(value.CurrencyCode)
      },
      deep: true,
    },
  },
  methods: {
    open(packageId) {
      this.isOpen = true
      this.packageId = packageId
      if (this.packageId) {
        this.getDetailPackage()
      } else {
        this.detailMasterPackage = {
          DistributionMethod: 'ByTurn',
          OpenDateSell: this.getUTC('start', 'day'),
          CloseDateSell: this.getUTC('start', 'day'),
          StartDate: this.getUTC('start', 'day'),
          ExpirationDate: this.getUTC('start', 'day'),
        }
      }
    },
    afterUpdateMasterPackage(res) {
      this.$emit('afterUpdateMasterPackage', res)
    },
    afterAddNewMasterPackage(res) {
      this.$emit('afterAddNewMasterPackage', res)
    },
    // Chi tiết Master Package
    async getDetailPackage() {
      this.isLoading = true
      const body = {
        PackageDr: {
          Id: this.packageId,
        },
      }
      await getDetailPackageDrivingRange2(body)
        .then(res => {
          if (res.Status === '200') {
            this.detailMasterPackage = res?.Data?.PackageDr
          } else {
            this.showResToast(res)
          }
        }).finally(() => {
          this.isLoading = false
        })
    },
    // Button
    addPackageAndViewDetail_onClick() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            PackageDr: {
              PackageCode: this.detailMasterPackage.PackageCode,
              CurrencyCode: this.detailMasterPackage.CurrencyCode,
              PackageName: this.detailMasterPackage.PackageName,
              Price: parseFloat(this.detailMasterPackage.Price),
              PlayNumber: parseInt(this.detailMasterPackage.PlayNumber, 10),
              Description: this.detailMasterPackage.Description,
              IsTaxable: this.detailMasterPackage.IsTaxable,
              IsRetail: this.detailMasterPackage.IsRetail,
              IsShare: this.detailMasterPackage.IsShare,
              IsPlayLimit: this.detailMasterPackage.IsPlayLimit,
              OpenDateSell: this.detailMasterPackage.OpenDateSell,
              CloseDateSell: this.detailMasterPackage.CloseDateSell,
              StartDate: this.detailMasterPackage.StartDate,
              ExpirationDate: this.detailMasterPackage.ExpirationDate,
              WeeklyCode: this.detailMasterPackage.WeeklyCode,
              ClientId: this.detailMasterPackage.ClientId,
              GolfClassId: this.detailMasterPackage.GolfClassId,
              DistributionMethod: this.detailMasterPackage.DistributionMethod,
              DistributionParameter: this.detailMasterPackage.DistributionParameter,
              DistributionNotUse: this.detailMasterPackage.DistributionNotUse,
              DistributionParameterDataType: 'FIX',
            },
          }
          addPackageDrivingRange(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterAddNewMasterPackage(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    btnUpdatePackage_onClick() {
      this.$refs.masterRateForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          const body = {
            PackageDr: {
              Id: this.packageId,
              PackageCode: this.detailMasterPackage.PackageCode,
              PackageName: this.detailMasterPackage.PackageName,
              Price: parseFloat(this.detailMasterPackage.Price),
              PlayNumber: parseInt(this.detailMasterPackage.PlayNumber, 10),
              Description: this.detailMasterPackage.Description,
              IsTaxable: this.detailMasterPackage.IsTaxable,
              IsRetail: this.detailMasterPackage.IsRetail,
              IsShare: this.detailMasterPackage.IsShare,
              IsPlayLimit: this.detailMasterPackage.IsPlayLimit,
              OpenDateSell: this.detailMasterPackage.OpenDateSell,
              CloseDateSell: this.detailMasterPackage.CloseDateSell,
              StartDate: this.detailMasterPackage.StartDate,
              ExpirationDate: this.detailMasterPackage.ExpirationDate,
              WeeklyCode: this.detailMasterPackage.WeeklyCode,
              ClientId: this.detailMasterPackage.ClientId,
              GolfClassId: this.detailMasterPackage.GolfClassId,
              CurrencyCode: this.detailMasterPackage.CurrencyCode,
              DistributionMethod: this.detailMasterPackage.DistributionMethod,
              DistributionParameter: this.detailMasterPackage.DistributionParameter,
              DistributionNotUse: this.detailMasterPackage.DistributionNotUse,
              DistributionParameterDataType: 'FIX',
            },
          }

          updatePackageDrivingRange(body)
            .then(res => {
              this.showResToast(res)
              if (res.Status === '200') {
                this.isOpen = false
                this.afterUpdateMasterPackage(res)
              }
            }).finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = '400px'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 0],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
  },
}
