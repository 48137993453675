import {
  ROOT,
  GET_LIST_PACKAGE_DRIVING_RANGE,
  GET_DETAIL_PACKAGE_DRIVING_RANGE1,
  GET_DETAIL_PACKAGE_DRIVING_RANGE2,
  ADD_PACKAGE_DRIVING_RANGE,
  UPDATE_PACKAGE_DRIVING_RANGE,
  DELETE_PACKAGE_DRIVING_RANGE,
  STOP_PACKAGE_DRIVING_RANGE,
  ENABLE_PACKAGE_DRIVING_RANGE,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

export function getListPackageDrivingRange(data) {
  return fetch(ROOT + GET_LIST_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function getDetailPackageDrivingRange1(data) {
  return fetch(ROOT + GET_DETAIL_PACKAGE_DRIVING_RANGE1, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function getDetailPackageDrivingRange2(data) {
  return fetch(ROOT + GET_DETAIL_PACKAGE_DRIVING_RANGE2, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function addPackageDrivingRange(data) {
  return fetch(ROOT + ADD_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function updatePackageDrivingRange(data) {
  return fetch(ROOT + UPDATE_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function deletePackageDrivingRange(data) {
  return fetch(ROOT + DELETE_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function stopPackageDrivingRange(data) {
  return fetch(ROOT + STOP_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}

export function enablePackageDrivingRange(data) {
  return fetch(ROOT + ENABLE_PACKAGE_DRIVING_RANGE, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data)
}
